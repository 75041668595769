import React from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { ArrowRight } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image";

const About = () => {
  return <>
    <Helmet>
      <title>About- Enriched</title>
      <meta name="description" content="Nicole Ninteau & Anna Cataldo" />
    </Helmet>
    <div className="about-container home-page">
      <div className="about">
          <div className="about-text">
            <h2>enriched</h2>
            <h6>Enriched was started to maximize the quality and longevity of every life we touch by minimizing the 
            confusion around nutrition science and facilitating impactful health behavior change.
            </h6>
            <div className="about-photos">
            <div className="about-photo" onClick={() => navigate('/nicole')}>
            <StaticImage
              src='../../static/assets/Nicole.png'
              alt="Nicole Ninteau"
            />
            <h4>Nicole Ninteau<br />
            <span style={{fontSize:'.7em'}}>PhD, MPH, CPT</span></h4>
            <p>Nicole Schultz Ninteau, PhD, MPH, CPT, embodies a rare combination of basic science and applied skills. 
            Studying Human Biochemistry and Metabolism for over 12 years, Nicole simultaneously coached clients in fitness 
            settings and taught students in academic settings. She has leveraged her deep scientific knowledge and 
            communication skills to elevate students’ learning while leading clients to weight loss and improved eating habits. 
            </p>
            <button>Learn More <ArrowRight size={14} /></button>
            </div>
            <div className="about-photo" onClick={() => navigate('/anna')}>
            <StaticImage
              src='../../static/assets/Anna.png'
              alt="Anna Cataldo"
            />
            <h4>Anna Cataldo<br />
            <span style={{fontSize:'.7em'}}>MS, MPH, RD</span></h4>
            <p>Anna Roto Cataldo, MS, MPH, RD, has studied and worked in the nutrition and fitness fields for 14+ years. 
            Her fascination with human metabolism and performance led her down a rigorous academic and clinical training 
            path in biochemistry, exercise physiology and nutrition science. Anna’s goal is to share her knowledge and 
            experiences in a way that helps others build confidence in and a sense of control over their health and well-being.  
            </p>
            <button>Learn More <ArrowRight size={14} /></button>
            </div>
            </div>
          </div>
        </div>
    </div>
  </>;
}

export default About
